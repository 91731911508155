import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from '../../core/services/event.service';
import { storageService } from '../../service/storage.service-new';
import { CommonServiceService } from '../../service/common-service.service';
declare var $: any;

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  pageHeading: string;
  notificationItems: Array<{}>;
  openMobileMenu: boolean;
  public name: any;

  //public commonService:any;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  @Input('topbarName') set topbarName(value) {
    //console.log(value);
    this.name = value;
  }

  constructor(
    private router: Router,
    public commonService: CommonServiceService,
    private eventService: EventService,
    private storageService: storageService
  ) { }

  ngOnInit() {
    var routeLink = this.router.url;
    this.commonService.autoLogout(routeLink);
    this._fetchNotifications();
    this.openMobileMenu = false;

    $("#openit").click(function () {
      $("body").toggleClass("enlarged");
    });

    this.storageService.getItem('fullName').then((dbres) => {
      if (dbres) {
        this.commonService.user = {
          name: dbres,
          id: null
        }
        this.storageService.getItem('id').then((dbres1) => {
          if (dbres1) {
            this.commonService.user.id = dbres1
          }
        });
      } else {
        this.commonService.user = undefined;
      }
    });
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    this.storageService.deleteItem('token');
    this.storageService.deleteItem('fullName');
    this.storageService.deleteItem('emailID');
    this.storageService.deleteItem('nextpre');
    this.router.navigate(['']);
  }

  _fetchNotifications() {
    this.notificationItems = [{
      text: 'Caleb Flakelar commented on Admin',
      subText: '1 min ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'primary',
      redirectTo: '/notification/1'
    },
    {
      text: 'New user registered.',
      subText: '5 min ago',
      icon: 'mdi mdi-account-plus',
      bgColor: 'info',
      redirectTo: '/notification/2'
    },
    {
      text: 'Cristina Pride',
      subText: 'Hi, How are you? What about our next meeting',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'success',
      redirectTo: '/notification/3'
    },
    {
      text: 'Caleb Flakelar commented on Admin',
      subText: '2 days ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'danger',
      redirectTo: '/notification/4'
    },
    {
      text: 'Caleb Flakelar commented on Admin',
      subText: '1 min ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'primary',
      redirectTo: '/notification/5'
    },
    {
      text: 'New user registered.',
      subText: '5 min ago',
      icon: 'mdi mdi-account-plus',
      bgColor: 'info',
      redirectTo: '/notification/6'
    },
    {
      text: 'Cristina Pride',
      subText: 'Hi, How are you? What about our next meeting',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'success',
      redirectTo: '/notification/7'
    },
    {
      text: 'Caleb Flakelar commented on Admin',
      subText: '2 days ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'danger',
      redirectTo: '/notification/8'
    }];
  }

}
